type Deck = any;
type Pile = any;
type Hand = any;
type PileId = string;
type HandId = string;

export enum ActionType {
  START_ROUND = 'start_round',
  SYNC = 'sync',
  PEEK_CARD = 'peek_card',
  PEEK_DONE = 'peek_done',
  DRAW = 'draw',
  DISCARD = 'discard',
  MOVE = 'move',
  SWAP = 'swap',
  SWAP_INTO_HAND = 'swap_into_hand',
  PENALTY = 'penalty',
  RESOLVE_PENALTY = 'resolve_penalty',
}

export type Action =
  | Action_StartRound
  | Action_Sync
  | Action_PeekCard
  | Action_PeekDone
  | Action_Draw
  | Action_Discard
  | Action_Move
  | Action_Swap
  | Action_SwapIntoHand
  | Action_Penalty
  | Action_ResolvePenalty;

/*
 *  Start Round
 *
 *  This is a dummy action that merely serves as a marker in the action stream
 *  for where one round ends and a new one begins
 */

// tslint:disable-next-line:class-name
interface Action_StartRound {
  type: ActionType.START_ROUND;
}

// tslint:disable-next-line:variable-name
export const createAction_StartRound = (): Action_StartRound => {
  return {
    type: ActionType.START_ROUND,
  };
};

/*
 *  Sync
 *
 *  Represents a full state of the table.
 *  Used as a "base" state to diff the subsequents actions off of.
 */

// tslint:disable-next-line:class-name
interface Action_Sync {
  type: ActionType.SYNC;
  table: any; // Keys are clique ids, values are arrays of card values
}

// tslint:disable-next-line:variable-name
export const createAction_Sync = (deck: Deck): Action_Sync => {
  return {
    type: ActionType.SYNC,
    table: deck.serialize(),
  };
};

// tslint:disable-next-line:variable-name
const executeAction_Sync = (action: Action_Sync, deck: Deck) => {
  const { table } = action;
  // tslint:disable-next-line:forin
  for (const cliqueId in deck.cliques) {
    const clique = deck.cliques[cliqueId];
    const cliqueUpdates = table[cliqueId] ? table[cliqueId] : null;
    if (cliqueUpdates) {
      if (cliqueUpdates.cardIds) {
        clique.gatherCards(cliqueUpdates.cardIds);
      } else {
        clique.gatherCards([]);
      }
    }
    clique.layout();
  }
};

/*
 *  Peek Card
 *
 *  Represents a player peeking at one of their cards at the beginning of a hand
 */

// tslint:disable-next-line:class-name
interface Action_PeekCard {
  type: ActionType.PEEK_CARD;
  handId: HandId;
  index: number; // The index of the card in the hand that is begin peeked at
}

// tslint:disable-next-line:variable-name
export const createAction_PeekCard = (
  hand: Hand,
  idx: number
): Action_PeekCard => {
  return {
    type: ActionType.PEEK_CARD,
    handId: hand.id,
    index: idx,
  };
};

// tslint:disable-next-line:variable-name
const executeAction_PeekCard = (action: Action_PeekCard, deck: Deck) => {
  const { handId, index } = action;
  const hand = deck.cliques[handId];

  hand.setCardProminentAtIndex(index, true);
  hand.layout();
};

/*
 *  Peek Done
 *
 *  Represents a player finishing peeking at their cards at the beginning of a turn
 */

// tslint:disable-next-line:class-name
interface Action_PeekDone {
  type: ActionType.PEEK_DONE;
  handId: HandId;
}

// tslint:disable-next-line:variable-name
export const createAction_PeekDone = (hand: Hand): Action_PeekDone => {
  return {
    type: ActionType.PEEK_DONE,
    handId: hand.id,
  };
};

// tslint:disable-next-line:variable-name
const executeAction_PeekDone = (action: Action_PeekDone, deck: Deck) => {
  const { handId } = action;
  const hand = deck.cliques[handId];

  hand.clearProminentCards();
  hand.layout();
};

/*
 *  Draw
 */

// tslint:disable-next-line:class-name
interface Action_Draw {
  type: ActionType.DRAW;
  from: PileId;
  to: HandId;
}

// tslint:disable-next-line:variable-name
export const createAction_Draw = (from: Pile, to: Hand): Action_Draw => {
  return {
    type: ActionType.DRAW,
    from: from.id,
    to: to.id,
  };
};

// tslint:disable-next-line:variable-name
const executeAction_Draw = (action: Action_Draw, deck: Deck) => {
  const { from, to } = action;
  const fromPile = deck.cliques[from];
  const toHand = deck.cliques[to];

  const drawn = fromPile.popCard();
  toHand.addCard(drawn);
  toHand.layout();
};

/*
 *  Discard
 */

// tslint:disable-next-line:class-name
interface Action_Discard {
  type: ActionType.DISCARD;
  from: HandId;
  fromIndices: number[];
  to: PileId;
}

// tslint:disable-next-line:variable-name
export const createAction_Discard = (
  from: Hand,
  fromIndices: number[],
  to: Pile
): Action_Discard => {
  return {
    type: ActionType.DISCARD,
    from: from.id,
    fromIndices,
    to: to.id,
  };
};

// tslint:disable-next-line:variable-name
const executeAction_Discard = (action: Action_Discard, deck: Deck) => {
  const { from, fromIndices, to } = action;
  const fromHand = deck.cliques[from];
  const toPile = deck.cliques[to];

  // Remove in reverse
  fromIndices.sort();
  for (let i = fromIndices.length - 1; i >= 0; --i) {
    const card = fromHand.removeCardAtIndex(fromIndices[i]);
    toPile.pushCard(card);
  }

  fromHand.layout();
  toPile.layout();
};

/*
 *  Discard
 */

// tslint:disable-next-line:class-name
interface Action_Move {
  type: ActionType.MOVE;
  from: HandId;
  fromIndex: number;
  to: HandId;
  toIndex: number;
}

// tslint:disable-next-line:variable-name
export const createAction_Move = (
  from: Hand,
  fromIndex: number,
  to: Hand,
  toIndex: number
): Action_Move => {
  return {
    type: ActionType.MOVE,
    from: from.id,
    fromIndex,
    to: to.id,
    toIndex,
  };
};

// tslint:disable-next-line:variable-name
const executeAction_Move = (action: Action_Move, deck: Deck) => {
  // tslint:disable-next-line:prefer-const
  let { from, fromIndex, to, toIndex } = action;
  const fromHand = deck.cliques[from];
  const toHand = deck.cliques[to];

  if (from === to) {
    // Moving within the same clique, so be careful about the indices
    // In particular, we resolve indices based on the spot each would
    // refer to before any moving of cards.

    if (toIndex > fromIndex) {
      toIndex--;
    }
  } else {
    const card = fromHand.removeCardAtIndex(fromIndex);
    toHand.insertCardAtIndex(card, toIndex);
  }

  fromHand.layout();
  toHand.layout();
};

/*
 *  Swap
 */

// tslint:disable-next-line:class-name
interface Action_Swap {
  type: ActionType.SWAP;
  hand1: HandId;
  hand1Index: number;
  hand2: HandId;
  hand2Index: number;
}

// tslint:disable-next-line:variable-name
export const createAction_Swap = (
  hand1: Hand,
  hand1Index: number,
  hand2: Hand,
  hand2Index: number
): Action_Swap => {
  return {
    type: ActionType.SWAP,
    hand1: hand1.id,
    hand1Index,
    hand2: hand2.id,
    hand2Index,
  };
};

// tslint:disable-next-line:variable-name
const executeAction_Swap = (action: Action_Swap, deck: Deck) => {
  const { hand1, hand1Index, hand2, hand2Index } = action;
  const handOne = deck.cliques[hand1];
  const handTwo = deck.cliques[hand2];

  if (hand1 === hand2) {
    // Moving within the same clique, so be careful about the indices
    // In particular, remove backwards, insert forwards
    if (hand2Index > hand1Index) {
      const card2 = handTwo.removeCardAtIndex(hand2Index);
      const card1 = handOne.removeCardAtIndex(hand1Index);
      handOne.insertCardAtIndex(card2, hand1Index);
      handTwo.insertCardAtIndex(card1, hand2Index);
    } else {
      const card1 = handOne.removeCardAtIndex(hand1Index);
      const card2 = handTwo.removeCardAtIndex(hand2Index);
      handTwo.insertCardAtIndex(card1, hand2Index);
      handOne.insertCardAtIndex(card2, hand1Index);
    }
  } else {
    const card1 = handOne.removeCardAtIndex(hand1Index);
    const card2 = handTwo.removeCardAtIndex(hand2Index);
    handOne.insertCardAtIndex(card2, hand1Index);
    handTwo.insertCardAtIndex(card1, hand2Index);
  }

  handOne.layout();
  handTwo.layout();
};

/*
 *  Swap from play area into hand
 */

// tslint:disable-next-line:class-name
interface Action_SwapIntoHand {
  type: ActionType.SWAP_INTO_HAND;
  from: HandId;
  fromIndex: number;
  to: HandId;
  toIndex: number;
  discard: PileId;
}

// tslint:disable-next-line:variable-name
export const createAction_SwapIntoHand = (
  from: Hand,
  fromIndex: number,
  to: Hand,
  toIndex: number,
  discardPile: Pile
): Action_SwapIntoHand => {
  return {
    type: ActionType.SWAP_INTO_HAND,
    from: from.id,
    fromIndex,
    to: to.id,
    toIndex,
    discard: discardPile.id,
  };
};

// tslint:disable-next-line:variable-name
const executeAction_SwapIntoHand = (
  action: Action_SwapIntoHand,
  deck: Deck
) => {
  const { from, fromIndex, to, toIndex, discard } = action;
  const fromPlayArea = deck.cliques[from];
  const toHand = deck.cliques[to];
  const discardPile = deck.cliques[discard];

  const newCard = fromPlayArea.removeCardAtIndex(fromIndex);
  const swapped = toHand.replaceCardAtIndex(toIndex, newCard);
  discardPile.pushCard(swapped);

  fromPlayArea.layout();
  toHand.layout();
  discardPile.layout();
};

/*
 *  Move cards to show penalty
 */

// tslint:disable-next-line:class-name
interface Action_Penalty {
  type: ActionType.PENALTY;
  from: HandId;
  fromIndices: number[];
  to: HandId;
}

// tslint:disable-next-line:variable-name
export const createAction_Penalty = (
  from: Hand,
  fromIndices: number[],
  to: Hand
): Action_Penalty => {
  return {
    type: ActionType.PENALTY,
    from: from.id,
    fromIndices,
    to: to.id,
  };
};

// tslint:disable-next-line:variable-name
const executeAction_Penalty = (action: Action_Penalty, deck: Deck) => {
  const { from, fromIndices, to } = action;
  const fromHand = deck.cliques[from];
  const toHand = deck.cliques[to];

  // Remove in reverse
  fromIndices.sort();
  const cards = [];
  for (let i = fromIndices.length - 1; i >= 0; --i) {
    const card = fromHand.removeCardAtIndex(fromIndices[i]);
    cards.unshift(card);
  }
  cards.forEach((card) => toHand.addCard(card));

  fromHand.layout();
  toHand.layout();
};

/*
 *  Resolve penalty
 */

// tslint:disable-next-line:class-name
interface Action_ResolvePenalty {
  type: ActionType.RESOLVE_PENALTY;
  from: HandId;
  to: HandId;
  originalIndices: number[];
  drawPileId: PileId;
}

// tslint:disable-next-line:variable-name
export const createAction_ResolvePenalty = (
  penalty: Action_Penalty,
  drawPile: Pile
): Action_ResolvePenalty => {
  return {
    type: ActionType.RESOLVE_PENALTY,
    from: penalty.to,
    to: penalty.from,
    originalIndices: penalty.fromIndices,
    drawPileId: drawPile.id,
  };
};

// tslint:disable-next-line:variable-name
const executeAction_ResolvePenalty = (
  action: Action_ResolvePenalty,
  deck: Deck
) => {
  const { from, to, originalIndices, drawPileId } = action;
  const fromPenaltyArea = deck.cliques[from]; // Penalty Area
  const toHand = deck.cliques[to]; // Original Hand
  const drawPile = deck.cliques[drawPileId];

  // Insert forward
  // tslint:disable-next-line:prefer-for-of
  for (let i = 0; i < originalIndices.length; ++i) {
    const card = fromPenaltyArea.removeCardAtIndex(0);
    toHand.insertCardAtIndex(card, originalIndices[i]);
  }
  toHand.layout('take cards back');

  // Take the penalty card
  toHand.addCard(drawPile.popCard());
  toHand.layout('take penalty card');

  fromPenaltyArea.layout();
  drawPile.layout();
  toHand.layout();
};

/*
 *  Execute Action Entry Point
 */

export const executeAction = (action: Action, deck: Deck) => {
  switch (action.type) {
    case ActionType.START_ROUND: {
      // No-op
      break;
    }
    case ActionType.SYNC: {
      executeAction_Sync(action as Action_Sync, deck);
      break;
    }
    case ActionType.PEEK_CARD: {
      executeAction_PeekCard(action as Action_PeekCard, deck);
      break;
    }
    case ActionType.PEEK_DONE: {
      executeAction_PeekDone(action as Action_PeekDone, deck);
      break;
    }
    case ActionType.DRAW: {
      executeAction_Draw(action as Action_Draw, deck);
      break;
    }
    case ActionType.DISCARD: {
      executeAction_Discard(action as Action_Discard, deck);
      break;
    }
    case ActionType.MOVE: {
      executeAction_Move(action as Action_Move, deck);
      break;
    }
    case ActionType.SWAP: {
      executeAction_Swap(action as Action_Swap, deck);
      break;
    }
    case ActionType.SWAP_INTO_HAND: {
      executeAction_SwapIntoHand(action as Action_SwapIntoHand, deck);
      break;
    }
    case ActionType.PENALTY: {
      executeAction_Penalty(action as Action_Penalty, deck);
      break;
    }
    case ActionType.RESOLVE_PENALTY: {
      executeAction_ResolvePenalty(action as Action_ResolvePenalty, deck);
      break;
    }
  }
};
