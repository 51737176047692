import React, {
  createContext,
  Dispatch,
  Reducer,
  useContext,
  useReducer,
} from 'react';
import { ActionType } from './reducer';
import IPlayerDocument from './types/IPlayerDocument';

export interface IGlobalState {
  player: IPlayerDocument | null;
  game: string | null;
}

interface IProps {
  reducer: Reducer<any, any>;
  initialState: IGlobalState;
  children: any;
}

type IContextProps = [
  IGlobalState,
  Dispatch<{ type: ActionType; payload: any }>
];

// tslint:disable-next-line:no-object-literal-type-assertion
export const StateContext = createContext({} as IContextProps);
export const StateProvider = ({ reducer, initialState, children }: IProps) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>
);
export const useStateValue = () => useContext(StateContext);
