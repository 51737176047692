import * as firebase from 'firebase/app';
import 'firebase/auth';
import React, { useState } from 'react';
import { useListVals } from 'react-firebase-hooks/database';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  Confirm,
  Container,
  Form,
  Header,
  Image,
  Loader,
  Message,
  Modal,
} from 'semantic-ui-react';
import GameCard from '../components/GameCard';
import { Collection, GameStatus } from '../constants';
import { ActionType } from '../reducer';
import { useStateValue } from '../state';
import { IGame } from '../types/IGameDocument';

// tslint:disable-next-line:no-var-requires
const newGameSvgUrl = require('../styles/assets/plus-blue.png');

interface IGameLists {
  myGames: IGame[];
  joinableGames: IGame[];
  spectateableGames: IGame[];
}

function getGameListDefaults(): IGameLists {
  return Object.create({
    myGames: [],
    joinableGames: [],
    spectateableGames: [],
  });
}

const Lobby = () => {
  const [{ player }, dispatch] = useStateValue();
  const [gameName, setGameName] = useState('');
  const [gameCreateError, setGameCreateError] = useState<Error | null>(null);
  const [gameCreateSuccess, setGameCreateSuccess] = useState(false);
  const [gameCreateLoading, setGameCreateLoading] = useState(false);
  const [showJoinConfirm, setShowJoinConfirm] = useState(false);
  const [gameJoinError, setGameJoinError] = useState<Error | null>(null);
  const [gameToJoin, setGameToJoin] = useState('');
  const history = useHistory();

  const [games, gamesLoading, gamesError] = useListVals<IGame>(
    firebase.database().ref(`games`),
    { keyField: 'id' }
  );

  // TODO may be a good candidate for useMemo
  const { myGames, joinableGames, spectateableGames }: IGameLists = games
    ? games.reduce<IGameLists>((acc, _game) => {
        if (_game.players.find((g) => g.id === player?.id)) {
          acc.myGames.push(_game);
        } else if (
          _game.status === GameStatus.PENDING ||
          _game.status === GameStatus.READY
        ) {
          acc.joinableGames.push(_game);
        } else if (_game.status === GameStatus.OPEN) {
          acc.spectateableGames.push(_game);
        }
        return acc;
      }, getGameListDefaults())
    : getGameListDefaults();

  const setGame = (gameId: string) => {
    dispatch({ type: ActionType.SET_GAME, payload: gameId });
  };

  const joinGame = async (gameId: string) => {
    try {
      const gameSnapshot = await firebase
        .database()
        .ref(`games/${gameId}`)
        .once('value');
      const { players } = gameSnapshot.val() as IGame;
      players.push({
        name: player?.data.name || '',
        avatar: player?.data.avatar || '',
        id: player?.ref.id!,
        handClique: null,
        drawClique: null,
        peeked: false,
        readyForNextHand: true,
        scores: null,
      });
      await firebase.database().ref(`games/${gameId}/players`).set(players);
      setGameToJoin('');
      setShowJoinConfirm(false);
      setGame(gameId);
      history.push(`/game/${gameId}`);
    } catch (e) {
      setGameJoinError(e);
    }
  };

  const createGame = async () => {
    setGameCreateLoading(true);
    if (player) {
      try {
        const newGame = await firebase
          .firestore()
          .collection(Collection.GAMES)
          .add({
            name: gameName,
          });
        await firebase
          .database()
          .ref(`games/${newGame.id}`)
          .set({
            name: gameName,
            createdBy: player.ref.id,
            players: [
              {
                id: player.ref.id,
                name: player.data.name,
                avatar: player.data.avatar || '',
                handClique: null,
                drawClique: null,
                peeked: false,
                readyForNextHand: true,
                scores: null,
              },
            ],
            status: GameStatus.PENDING,
          });
        setGameCreateSuccess(true);
        setGameName('');
        setGameCreateLoading(false);
        dispatch({
          type: ActionType.SET_GAME,
          payload: newGame.id,
        });
      } catch (e) {
        setGameCreateError(e);
        setGameCreateLoading(false);
      }
    }
  };

  return (
    <div className="lobby-container">
      <Container className="lobby-container">
        <Header size="huge" className="fancy">
          My Games
        </Header>

        {gamesLoading && <Loader />}
        {gamesError && gamesError.message}
        {gameJoinError && gameJoinError.message}
        <Card.Group itemsPerRow={5}>
          <Modal
            trigger={
              <Card className="lobby-card new-game" as="a">
                <Image src={newGameSvgUrl} wrapped ui={false} />
                <Card.Content>
                  <Card.Header>New Game</Card.Header>
                </Card.Content>
              </Card>
            }
          >
            <Modal.Header>Create A New Game</Modal.Header>
            <Modal.Content>
              <Form>
                <Form.Input
                  fluid
                  icon="user"
                  iconPosition="left"
                  placeholder="Name"
                  onChange={(e, { value }) => setGameName(value)}
                />
                {gameCreateSuccess ? (
                  <Header>Game created!</Header>
                ) : (
                  <Button
                    color="teal"
                    fluid
                    size="large"
                    onClick={createGame}
                    disabled={!gameName || gameCreateLoading}
                  >
                    Create
                  </Button>
                )}
                <Message
                  error
                  header="Error creating game"
                  content={gameCreateError?.message || 'Unknown Error'}
                />
              </Form>
            </Modal.Content>
          </Modal>

          {myGames &&
            myGames.map((game) => (
              <GameCard
                key={game.id}
                game={game}
                onClick={() => {
                  history.push(`/game/${game.id}`);
                }}
              />
            ))}
        </Card.Group>

        <Header size="huge" className="fancy">
          Join A Game
        </Header>
        {gamesLoading && <Loader />}
        <Card.Group itemsPerRow={5}>
          {joinableGames &&
            joinableGames.map((game) => (
              <GameCard
                key={game.id}
                game={game}
                onClick={() => {
                  setGameToJoin(game.id);
                  setShowJoinConfirm(true);
                }}
              />
            ))}
        </Card.Group>

        <Header size="huge" className="fancy">
          Spectate
        </Header>
        {gamesLoading && <Loader />}
        <Card.Group itemsPerRow={5}>
          {spectateableGames &&
            spectateableGames.map((game) => (
              <GameCard
                key={game.id}
                game={game}
                onClick={() => {
                  history.push(`/game/${game.id}`);
                }}
              />
            ))}
        </Card.Group>

        <Confirm
          open={showJoinConfirm}
          header="Join Game"
          content={`Ready to join ${
            games?.find(({ id }) => id === gameToJoin)?.name || 'Unknown'
          }?`}
          onCancel={() => {
            setGameToJoin('');
            setShowJoinConfirm(false);
          }}
          onConfirm={() => joinGame(gameToJoin)}
        />
      </Container>
    </div>
  );
};

export default Lobby;
