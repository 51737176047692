import firebase from 'firebase';
import IFirestoreDocument from '../types/IFirestoreDocument';

export default function createDocument<T>(
  doc: firebase.firestore.DocumentSnapshot<T | firebase.firestore.DocumentData>,
  data?: T
): IFirestoreDocument<T> {
  return {
    ref: doc.ref,
    data: data || (doc.data() as T),
    id: doc.id,
  };
}
