import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import React, { useState } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, Form, Grid, Message } from 'semantic-ui-react';
import { useStateValue } from '../state';

const Login = () => {
  const [{ player }] = useStateValue();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [signInError, setSignInError] = useState<Error | null>(null);

  const login = async () => {
    setSignInError(null);
    try {
      await firebase.auth().signInWithEmailAndPassword(username, password);
    } catch (e) {
      setSignInError(e);
    }
  };
  if (player) {
    return <Redirect to="/" />;
  }
  return (
    <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Form size="large" onSubmit={login} error={!!signInError}>
          <Form.Input
            fluid
            icon="user"
            iconPosition="left"
            placeholder="E-mail address"
            onChange={(e, { value }) => setUsername(value)}
          />
          <Form.Input
            fluid
            icon="lock"
            iconPosition="left"
            placeholder="Password"
            type="password"
            onChange={(e, { value }) => setPassword(value)}
          />

          <Button color="teal" fluid size="large">
            Login
          </Button>
          <Message
            error
            header="Error signing in"
            content={signInError?.message || 'Unknown Error'}
          />
        </Form>
        <Link to="/register">Create a new account</Link>
      </Grid.Column>
    </Grid>
  );
};

export default Login;
