import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import React, { useState } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, Form, Grid, Message } from 'semantic-ui-react';
import { Collection } from '../constants';
import { useStateValue } from '../state';

const Register = () => {
  const [{ player }] = useStateValue();
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [signInError, setSignInError] = useState<Error | null>(null);

  const register = async () => {
    setSignInError(null);
    try {
      const { user } = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password);
      await firebase
        .firestore()
        .collection(Collection.PLAYERS)
        .doc(user?.uid)
        .set({
          email,
          name: username,
        });
      await firebase.auth().signInWithEmailAndPassword(email, password);
    } catch (e) {
      setSignInError(e);
    }
  };
  if (player) {
    return <Redirect to="/" />;
  }
  return (
    <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Form
          size="large"
          onSubmit={register}
          error={!!signInError}
          autoComplete="off"
        >
          <Form.Input
            fluid
            icon="mail"
            iconPosition="left"
            placeholder="E-mail address"
            autoComplete="off"
            onChange={(e, { value }) => setEmail(value)}
          />
          <Form.Input
            fluid
            icon="user"
            iconPosition="left"
            placeholder="User Name"
            autoComplete="off"
            onChange={(e, { value }) => setUsername(value)}
          />
          <Form.Input
            fluid
            icon="lock"
            iconPosition="left"
            placeholder="Password"
            type="password"
            autoComplete="off"
            onChange={(e, { value }) => setPassword(value)}
          />

          <Button color="teal" fluid size="large">
            Create Account
          </Button>
          <Message
            error
            header="Error registering"
            content={signInError?.message || 'Unknown Error'}
          />
        </Form>
        <Link to="/login">Have an account? Log in</Link>
      </Grid.Column>
    </Grid>
  );
};

export default Register;
