export const DEBUG = process.env.NODE_ENV === 'development';

export enum Collection {
  PLAYERS = 'players',
  GAMES = 'games',
}

export enum GameStatus {
  PENDING = 'pending',
  READY = 'ready',
  OPEN = 'open',
  CLOSED = 'closed',
}

export enum GamePhase {
  DEAL = 'deal',
  PEEK = 'peek',
  PLAY = 'play',
  BOTRICE = 'botrice',
  GAME_OVER = 'game_over',
}

export enum CardRank {
  JACK = 11,
  QUEEN = 12,
  KING = 13,
}
