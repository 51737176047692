import firebase from 'firebase';
import IRTDBDocument from '../types/IRTDBDocument';

export default function createDocument<T>(
  doc: firebase.database.DataSnapshot,
  id: string
): IRTDBDocument<T> {
  return {
    ref: doc.ref,
    data: doc.val(),
    id,
  };
}
