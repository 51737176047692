import { IGlobalState } from './state';

export enum ActionType {
  SET_PLAYER = 'botrice/SET_PLAYER',
  SET_GAME = 'botrice/SET_GAME',
}

export interface IAction {
  type: ActionType;
  payload?: any;
}

const reducer = (
  state: Partial<IGlobalState>,
  action: IAction
): Partial<IGlobalState> => {
  switch (action.type) {
    case ActionType.SET_PLAYER:
      return { ...state, player: action.payload };
    case ActionType.SET_GAME:
      return { ...state, game: action.payload };
    default:
      return state;
  }
};

export default reducer;
