import Deck from 'deck-of-cards';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import {
  Button,
  Container,
  Feed,
  Grid,
  Header,
  Image,
  Loader,
  Rail,
  Segment,
  Table,
} from 'semantic-ui-react';
import { Collection, DEBUG, GamePhase, GameStatus } from '../constants';
import { useStateValue } from '../state';
import IGameDocument from '../types/IGameDocument';
import { IPlayer } from '../types/IPlayerDocument';
import getAvatar from '../utils/getAvatar';

import {
  Action,
  ActionType,
  createAction_StartRound,
  createAction_Sync,
  createAction_PeekCard,
  createAction_PeekDone,
  executeAction,
} from './Actions';

import {
  forceDeal,
  forcePeek,
  multiplesScenario,
  newGame,
} from './DEBUG_testGames';
import DebugControls from './DebugControls';
import {
  createHelperButton,
  createHelperHeader,
  createHelperLabel,
  myTurn,
  TurnState,
} from './gameLogic';

interface IProps {
  game: IGameDocument;
  handleUpdateGame: (data: any, path?: string) => Promise<void>;
  updateError: Error | null;
}

interface ILocalState {
  tableReady: boolean;
  deck: any;
  drawPile: any;
  discardPile: any;
  botriceArea: any;
  hands: any;
  playAreas: any;
  penaltyAreas: any;
  myHand: any;
  myPlayArea: any;
  myPenaltyArea: any;
  tableLocked: boolean;
  dealing: boolean;
  peeking: boolean;
  latestActionId: any;
}

enum IActionType {
  UPDATE = 'botrice/game/UPDATE',
}

interface IAction {
  type: IActionType;
  payload: Partial<ILocalState>;
}

const initialLocalState: ILocalState = {
  tableReady: false,
  deck: {},
  drawPile: {},
  discardPile: {},
  botriceArea: {},
  hands: [],
  playAreas: [],
  penaltyAreas: [],
  myHand: null,
  myPlayArea: null,
  myPenaltyArea: null,
  tableLocked: false,
  dealing: false,
  peeking: false,
  latestActionId: null,
};

function localStateReducer(state: ILocalState, action: IAction) {
  switch (action.type) {
    case IActionType.UPDATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

const PlayArea = ({ game, handleUpdateGame, updateError }: IProps) => {
  const {
    name: gameName,
    status: gameStatus,
    players: playerRefs,
    createdBy,
    dealer,
    player: currentPlayer,
    turnState,
    phase,
    round,
  } = game.data;

  // Turn the actions into a usable data structure
  let actions: any[] = [];
  if (game.data.actions) {
    actions = Object.entries(game.data.actions).map(([key, val]: any[]) => {
      return { id: key, ...val };
    });
  }

  const query = firebase
    .firestore()
    .collection(Collection.PLAYERS)
    .where(
      firebase.firestore.FieldPath.documentId(),
      'in',
      playerRefs.map(({ id }) => id)
    );
  const [players = [], playersLoading] = useCollectionData<IPlayer>(query, {
    idField: 'id',
  });

  const [{ player }] = useStateValue();

  const playerIndex = useMemo(
    () => playerRefs.findIndex((p) => p.id === player?.id),
    // eslint-disable-next-line
    []
  );

  // Assemble scores into UI
  let scoreRows = [];
  for (let roundNum = 1; roundNum <= round; ++roundNum) {
    scoreRows.push(
      <Table.Row key={'scoresRound' + roundNum}>
        <Table.Cell>{roundNum}</Table.Cell>
        {playerRefs.map((playerRef) => {
          const player = players.find((p) => p.id === playerRef.id)!;
          if (!player) {
            return <Table.Cell key={playerRef.id}></Table.Cell>;
          }
          const scores = playerRef?.scores ? playerRef.scores : [];
          return (
            <Table.Cell key={player.id}>{scores[roundNum - 1]}</Table.Cell>
          );
        })}
      </Table.Row>
    );
  }
  let scoreTotals = (
    <Table.Row>
      <Table.Cell>Totals</Table.Cell>
      {playerRefs.map((playerRef) => {
        const player = players.find((p) => p.id === playerRef.id)!;
        if (!player) {
          return <Table.Cell key={playerRef.id}></Table.Cell>;
        }
        const scores = playerRef?.scores ? playerRef.scores : [];
        return (
          <Table.Cell key={player.id}>
            {scores.reduce((acc, curr) => acc + curr, 0)}
          </Table.Cell>
        );
      })}
    </Table.Row>
  );

  const imTheCreator = createdBy === player?.id;
  const imTheDealer = dealer === player?.id;
  const itsMyTurn = currentPlayer === player?.id;

  const [showDebugControls, setShowDebugControls] = useState(false);

  const [
    {
      tableReady,
      deck,
      drawPile,
      discardPile,
      botriceArea,
      hands,
      playAreas,
      penaltyAreas,
      myHand,
      myPlayArea,
      myPenaltyArea,
      tableLocked,
      dealing,
      peeking,
      latestActionId,
    },
    dispatchLocalState,
  ] = useReducer(localStateReducer, initialLocalState);

  useEffect(() => {
    if (playersLoading) {
      return;
    }
    if (gameStatus !== GameStatus.OPEN) {
      return;
    }

    // Game setup
    if (!tableReady) {
      setupTable();
      return;
    }

    if (!tableLocked) {
      // Sync table state
      resolveTable();
    } else {
      return;
    }

    if (phase === GamePhase.GAME_OVER) {
      // Show hands
      hands.forEach((hand: any) => {
        hand.side = Deck.Card.Side.FRONT;
        hand.layout();
      });

      botriceArea.showHelper(true, {
        content: createHelperHeader('Game Over!'),
      });

      return;
    }

    if (phase === GamePhase.BOTRICE) {
      botriceArea.showHelper(true, {
        content: createHelperHeader('Botrice!'),
      });

      // Show hands
      hands.forEach((hand: any) => {
        hand.side = Deck.Card.Side.FRONT;
        hand.layout();
      });

      if (!playerRefs[playerIndex].readyForNextHand) {
        myPlayArea.showHelper(true, {
          content: createHelperButton('Ready for Next Hand', {
            size: 'large',
            color: 'orange',
            onClick: () => {
              myPlayArea.showHelper(true, {
                content: createHelperHeader('Waiting for other players', 'h3'),
              });
              handleUpdateGame(
                {
                  readyForNextHand: true,
                },
                `players/${playerIndex}`
              );
            },
          }),
        });
      } else if (imTheDealer) {
        // Dealer handles kicking off the next hand

        const totalReady = playerRefs.reduce((acc, curr) => {
          return acc + (curr.readyForNextHand ? 1 : 0);
        }, 0);
        if (totalReady === playerRefs.length) {
          // Return all the cards to the draw pile
          drawPile.removeAllCards(); // Just for good measure, start totally fresh
          discardPile.removeAllCards();
          botriceArea.removeAllCards();
          hands.forEach((clique: any) => clique.removeAllCards());
          playAreas.forEach((clique: any) => clique.removeAllCards());
          penaltyAreas.forEach((clique: any) => clique.removeAllCards());
          drawPile.cards = deck.cards;
          drawPile.layout();

          const startRoundAction = createAction_StartRound();
          const startRoundActionRef = game.ref.child('actions').push();

          const syncAction = createAction_Sync(deck);
          const syncActionRef = game.ref.child('actions').push();

          // Update Db
          const nextDealerId = getNextPlayer().id;
          const dbUpdates: any = {
            dealer: nextDealerId,
            phase: 'deal',
            round: round + 1,
          };
          // Create updated player structs
          playerRefs.forEach((playerRef, idx) => {
            dbUpdates[`/players/${idx}/peeked`] = false;
          });
          // Start round
          dbUpdates[`/actions/${startRoundActionRef.key}`] = {
            player: player?.id,
            action: startRoundAction,
          };
          // Sync table
          dbUpdates[`/actions/${syncActionRef.key}`] = {
            player: player?.id,
            action: syncAction,
          };

          // Kick off next hand
          handleUpdateGame(dbUpdates);
        }
      }

      return;
    }

    // Deal, if I'm the dealer
    if (phase === GamePhase.DEAL) {
      // Clear helpers and reset deck sides from last hand (if necessary)
      botriceArea.showHelper(false);
      myPlayArea.showHelper(false);
      hands.forEach((hand: any) => {
        hand.side = Deck.Card.Side.BACK;
        hand.layout();
      });

      if (imTheDealer && !dealing) {
        // Shuffle
        drawPile.shuffle();
        drawPile.shuffle();

        drawPile.queued((next: any) => {
          // Deal that shnizz!
          const startingPlayerIdx =
            playerIndex === players.length - 1 ? 0 : playerIndex + 1;

          for (let i = 0; i < 4; ++i) {
            for (
              let handIdx = startingPlayerIdx, iters = 0;
              iters < players.length;
              ++iters, handIdx = (handIdx + 1) % players.length
            ) {
              hands[handIdx].addCard(drawPile.popCard());
              hands[handIdx].layout(`dealing card ${i}`, { duration: 200 });
            }
          }
          drawPile.queued((next: any) => {
            const startRoundAction = createAction_StartRound();
            const startRoundActionRef = game.ref.child('actions').push();

            const syncAction = createAction_Sync(deck);
            const syncActionRef = game.ref.child('actions').push();

            const dbUpdates: any = {
              player: getNextPlayer().id,
              phase: 'peek',
              turnState: TurnState.START,
            };
            // Start round
            dbUpdates[`/actions/${startRoundActionRef.key}`] = {
              player: player?.id,
              action: startRoundAction,
            };
            // Sync table
            dbUpdates[`/actions/${syncActionRef.key}`] = {
              player: player?.id,
              action: syncAction,
            };
            handleUpdateGame(dbUpdates);
            dispatchLocalState({
              type: IActionType.UPDATE,
              payload: {
                dealing: false,
              },
            });
            next();
          }, 'DRAW PILE: go to peek phase')();
          next();
        }, 'DRAW PILE: deal')();
        dispatchLocalState({
          type: IActionType.UPDATE,
          payload: {
            dealing: true,
          },
        });
      }

      return;
    }

    if (phase === GamePhase.PEEK) {
      // If I haven't peeked yet, peek
      if (!playerRefs[playerIndex].peeked && !peeking) {
        dispatchLocalState({
          type: IActionType.UPDATE,
          payload: {
            peeking: true,
          },
        });

        let totalPeeked = 0;
        myHand.onSelect = (selectedCard: any, index: number) => {
          selectedCard.setSide(Deck.Card.Side.FRONT);
          myHand.setCardSelectable(selectedCard, false);
          totalPeeked++;

          // pushAction(createAction_PeekCard(myHand, index));

          if (totalPeeked === 1) {
            myHand.showHelper(true, {
              content: createHelperLabel('Select one more card to peek at'),
            });
          } else if (totalPeeked === 2) {
            myHand.enableSelection(false);
            myHand.showHelper(true, {
              content: createHelperButton('Done Peeking', {
                onClick: () => {
                  myHand.showHelper(false);
                  myHand.cards.forEach((c: any) =>
                    c.setSide(Deck.Card.Side.BACK)
                  );
                  // pushAction(createAction_PeekDone(myHand));
                  handleUpdateGame(
                    {
                      peeked: true,
                    },
                    `players/${playerIndex}`
                  ).then(() => {
                    dispatchLocalState({
                      type: IActionType.UPDATE,
                      payload: {
                        peeking: false,
                      },
                    });
                  });
                },
              }),
            });
          }
        };
        myHand.showHelper(true, {
          content: createHelperLabel('Select two cards to peek at'),
        });
        myHand.enableSelection(true);
      } else if (playerRefs[playerIndex].peeked) {
        // Kick off the first hand once everyone's peeked, if I'm the dealer
        if (imTheDealer) {
          const totalPeeked = playerRefs.reduce((acc, curr) => {
            return acc + (curr.peeked ? 1 : 0);
          }, 0);
          if (totalPeeked === playerRefs.length) {
            handleUpdateGame({
              phase: 'play',
            });
          }
        }
      }

      return;
    }

    // If it's my turn, do stuff
    if (itsMyTurn) {
      makeMoves();
      return;
    }
  });

  const makeMoves = () => {
    // Lock table updates, so that we can manually
    // manage DOM updates during our turn.
    dispatchLocalState({
      type: IActionType.UPDATE,
      payload: {
        tableLocked: true,
      },
    });

    const tableState = {
      deck,
      actions,
      drawPile,
      discardPile,
      botriceArea,
      myHand,
      myPlayArea,
      myPenaltyArea,
      hands,
    };

    const gameApi = {
      pushAction,
      pushTurnState,
      endTurn,
      botrice,
    };

    let context;
    if (turnState === TurnState.PENALTY) {
      const penaltyAction = actions[actions.length - 1].action;
      context = { penaltyAction };
    } else if (turnState === TurnState.PEEKING) {
      const peekAction = actions[actions.length - 1].action;
      context = { peekAction };
    }

    myTurn(turnState as TurnState, tableState, gameApi, context);
  };

  const pushTurnState = (turnState: TurnState) => {
    handleUpdateGame({
      turnState,
    });
  };

  const pushAction = (action: Action) => {
    const actionRef = game.ref.child('actions').push();
    actionRef.set({
      player: player?.id,
      action,
    });
    dispatchLocalState({
      type: IActionType.UPDATE,
      payload: {
        latestActionId: actionRef.key,
      },
    });
  };

  const endTurn = () => {
    const nextPlayer = getNextPlayer().id;
    deck.queue((next: any) => {
      pushAction(createAction_Sync(deck));
      handleUpdateGame({
        player: nextPlayer,
        turnState: TurnState.START,
      });
      dispatchLocalState({
        type: IActionType.UPDATE,
        payload: {
          tableLocked: false,
        },
      });
      next();
    }, 'end turn');
  };

  const botrice = () => {
    // This function must be run by the client that calls Botrice

    // Calculate raw scores
    const scores = hands.map((hand: any) => {
      return hand.cards.reduce((acc: number, card: any) => {
        let cardVal = card.rank;
        if (card.rank === 10) {
          cardVal = 0;
        }
        if (card.rank > 10) {
          cardVal = 10;
        }
        return acc + cardVal;
      }, 0);
    });

    // Determine if I won (0 points), tied (normal points), or take a penalty (+10 points)
    const myScore = scores[playerIndex];
    const minScore = Math.min(...scores);
    const numPlayersWithMinScore = scores.reduce(
      (acc: number, curr: any) => (curr === minScore ? acc + 1 : acc),
      0
    );
    if (myScore === minScore) {
      if (numPlayersWithMinScore === 1) {
        // I win
        scores[playerIndex] = 0;
      }
      // Else, I tie (but it's a no-op in terms of scores)
    } else {
      // I lose
      scores[playerIndex] += 10;
    }

    // Create updated player structs
    const updatedPlayers: any[] = [];
    let maxScore = 0;
    playerRefs.forEach((playerRef, idx) => {
      const playerScores = playerRef.scores || [];
      playerScores.push(scores[idx]);
      const totalPlayerScore = playerScores.reduce(
        (acc: number, score: number) => acc + score,
        0
      );
      if (totalPlayerScore > maxScore) {
        maxScore = totalPlayerScore;
      }
      updatedPlayers.push({
        id: playerRef.id,
        peeked: playerRef.peeked,
        scores: playerScores,
        readyForNextHand: false,
      });
    });

    // Check for game end
    let nextPhase = GamePhase.BOTRICE;
    if (maxScore >= 100) {
      // Game over!
      nextPhase = GamePhase.GAME_OVER;
    }

    // Update DB
    deck.queue((next: any) => {
      handleUpdateGame({
        phase: nextPhase,
        players: updatedPlayers,
      });
      dispatchLocalState({
        type: IActionType.UPDATE,
        payload: {
          tableLocked: false,
        },
      });
      next();
    }, 'push botrice to db');
  };

  const resolveTable = () => {
    if (actions && actions.length > 0) {
      // If we haven't synced the table at all yet (e.g., when loading or refreshing the page)
      // then do so, including playing any additional actions immediately to bring us in sync
      if (latestActionId === null) {
        // Find most recent table sync
        const syncIdx = actions
          .map((a) => a.action.type === ActionType.SYNC)
          .lastIndexOf(true);

        if (syncIdx === -1) {
          // tslint:disable-next-line:no-console
          console.log(
            'WARNING: Unable to find latest table sync action in queue'
          );
          return;
        }

        // Do everything in a single animation transaction
        deck.withQueueTransaction(() => {
          for (let i = syncIdx; i < actions.length; ++i) {
            const action = actions[i];
            executeAction(action.action, deck);
          }
        });

        dispatchLocalState({
          type: IActionType.UPDATE,
          payload: {
            latestActionId: actions[actions.length - 1].id,
          },
        });
      } else {
        const latestActionIdx = actions.findIndex(
          (a) => a.id === latestActionId
        );
        if (latestActionIdx === -1) {
          // Something weird happened and we lost sync. Do a manual sync by finding the
          // latest table sync, and executing it and each action after it (but still queue
          // the animations)

          // Find most recent table sync
          const syncIdx = actions
            .map((a) => a.action.type === ActionType.SYNC)
            .lastIndexOf(true);

          if (syncIdx === -1) {
            // tslint:disable-next-line:no-console
            console.log(
              'WARNING: Unable to find latest table sync action in queue'
            );
            return;
          }

          for (let i = syncIdx; i < actions.length; ++i) {
            const action = actions[i];
            executeAction(action.action, deck);
          }

          dispatchLocalState({
            type: IActionType.UPDATE,
            payload: {
              latestActionId: actions[actions.length - 1].id,
            },
          });
        } else if (latestActionIdx === actions.length - 1) {
          // Already up to date
          return;
        } else {
          // Run any new actions that we haven't run yet
          for (let i = latestActionIdx + 1; i < actions.length; ++i) {
            const action = actions[i];
            executeAction(action.action, deck);
          }
          dispatchLocalState({
            type: IActionType.UPDATE,
            payload: {
              latestActionId: actions[actions.length - 1].id,
            },
          });
        }
      }
    }
  };

  const setupTable = () => {
    // Create and mount deck
    const container = document.getElementById('card-table');
    const _deck = Deck();
    _deck.mount(container);

    // Create draw and discard piles
    const cardWidth = Deck.Util.getCardWidth(_deck);
    const cardHeight = Deck.Util.getCardHeight(_deck);
    const spacing = cardWidth / 4;
    const _drawPile = Deck.Clique.Pile(_deck, _deck.cards.slice(), {
      x: cardWidth / 2 + spacing / 2,
      y: 0,
      side: Deck.Card.Side.BACK,
    });

    const _discardPile = Deck.Clique.Pile(_deck, [], {
      x: -(cardWidth / 2 + spacing / 2),
      y: 0,
      side: Deck.Card.Side.FRONT,
    });

    // Create botrice area
    const _botriceArea = Deck.Clique.Hand(_deck, [], {
      x: 0,
      y: -(cardHeight + spacing),
      side: Deck.Card.Side.FRONT,
    });

    // Create hands and play areas

    // Always put me at the bottom of the screen
    const meX = 0;
    const meHandY = 250;
    const mePlayAreaY = 150;
    // const myPenaltyAreaY = 150; // This directly overlaps the play area, we make it separately to make it easier to show everyone the cards face up

    const _hands = [];
    const _playAreas = [];
    const _penaltyAreas = [];
    let _myHand = {};
    let _myPlayArea = {};
    let _myPenaltyArea = {};
    const idxOffset = playerIndex; // This determines how many "slots" around the table we're offset from the first player
    for (let i = 0; i < playerRefs.length; ++i) {
      const itsMe = i === playerIndex;
      const theta = ((2 * Math.PI) / playerRefs.length) * (i - idxOffset);
      const handX = meX * Math.cos(theta) - meHandY * Math.sin(theta);
      const handY = meX * Math.sin(theta) + meHandY * Math.cos(theta);
      const playAreaX = meX * Math.cos(theta) - mePlayAreaY * Math.sin(theta);
      const playAreaY = meX * Math.sin(theta) + mePlayAreaY * Math.cos(theta);
      const degs = theta * (180 / Math.PI);

      const hand = Deck.Clique.Hand(_deck, [], {
        x: handX,
        y: handY,
        rot: degs,
        side: Deck.Card.Side.BACK,
      });
      _hands.push(hand);

      const playArea = Deck.Clique.Hand(_deck, [], {
        x: playAreaX,
        y: playAreaY,
        rot: degs,
        side: itsMe ? Deck.Card.Side.FRONT : Deck.Card.Side.BACK,
      });
      _playAreas.push(playArea);

      const penaltyArea = Deck.Clique.Hand(_deck, [], {
        x: playAreaX, // Use the same coordinates as play area
        y: playAreaY,
        rot: degs,
        side: Deck.Card.Side.FRONT, // Unlike the play areas, these are always face up for everyone
      });
      _penaltyAreas.push(penaltyArea);

      if (itsMe) {
        _myHand = hand;
        _myPlayArea = playArea;
        _myPenaltyArea = penaltyArea;
      }
    }

    dispatchLocalState({
      type: IActionType.UPDATE,
      payload: {
        tableReady: true,
        deck: _deck,
        drawPile: _drawPile,
        discardPile: _discardPile,
        botriceArea: _botriceArea,
        hands: _hands,
        playAreas: _playAreas,
        penaltyAreas: _penaltyAreas,
        myHand: _myHand,
        myPlayArea: _myPlayArea,
        myPenaltyArea: _myPenaltyArea,
      },
    });
  };

  const getNextPlayer = () => {
    return playerIndex + 1 >= playerRefs.length
      ? playerRefs[0]
      : playerRefs[playerIndex + 1];
  };

  const startGame = async () => {
    // @TODO: Maybe pick a random dealer?
    const dealer = players[1].id;
    handleUpdateGame({
      dealer,
      status: GameStatus.OPEN,
      phase: 'deal',
      round: 1, // 1-based indexing!
    });
  };

  // tslint:disable-next-line:variable-name
  const DEBUG_resetGame = (dealerId: string) => {
    return () => {
      const playerIds = players.map((player) => player.id!);
      deck.unmount();
      dispatchLocalState({
        type: IActionType.UPDATE,
        payload: initialLocalState,
      });
      handleUpdateGame(newGame(playerIds, dealerId));
    };
  };

  // tslint:disable-next-line:variable-name
  const DEBUG_forceDeal = () => {
    if (tableLocked) {
      dispatchLocalState({
        type: IActionType.UPDATE,
        payload: {
          tableLocked: false,
        },
      });
    }
    const drawCliqueId = drawPile.id;
    const handCliqueIds = hands.map((hand: any) => hand.id);
    handleUpdateGame(forceDeal(drawCliqueId, handCliqueIds));
  };

  // tslint:disable-next-line:variable-name
  const DEBUG_forceMultiples = () => {
    if (tableLocked) {
      dispatchLocalState({
        type: IActionType.UPDATE,
        payload: {
          tableLocked: false,
        },
      });
    }
    const drawCliqueId = drawPile.id;
    const handCliqueIds = hands.map((hand: any) => hand.id);
    handleUpdateGame(multiplesScenario(drawCliqueId, handCliqueIds));
  };

  // tslint:disable-next-line:variable-name
  const DEBUG_forcePeek = () => {
    if (tableLocked) {
      dispatchLocalState({
        type: IActionType.UPDATE,
        payload: {
          tableLocked: false,
        },
      });
    }
    if (peeking) {
      myHand.enableSelection(false);
      myHand.showHelper(false);
      dispatchLocalState({
        type: IActionType.UPDATE,
        payload: {
          peeking: false,
        },
      });
    }
    const playerIds = players.map((player) => player.id!);
    handleUpdateGame(forcePeek(playerIds));
  };

  // tslint:disable-next-line:variable-name
  const DEBUG_forceTurn = (playerId: string) => {
    return () => {
      if (tableLocked) {
        dispatchLocalState({
          type: IActionType.UPDATE,
          payload: {
            tableLocked: false,
          },
        });
      }
      handleUpdateGame({
        player: playerId,
      });
    };
  };

  // tslint:disable-next-line:variable-name
  const DEBUG_showTable = () => {
    deck.withQueueTransaction(() => {
      drawPile.side = Deck.Card.Side.FRONT;
      drawPile.layout();
      hands.forEach((hand: any) => {
        hand.side = Deck.Card.Side.FRONT;
        hand.layout();
      });
      playAreas.forEach((playArea: any) => {
        playArea.side = Deck.Card.Side.FRONT;
        playArea.layout();
      });
    });
  };

  // tslint:disable-next-line:variable-name
  const DEBUG_hideTable = () => {
    deck.withQueueTransaction(() => {
      drawPile.side = Deck.Card.Side.BACK;
      drawPile.layout();
      hands.forEach((hand: any) => {
        hand.side = Deck.Card.Side.BACK;
        hand.layout();
      });
      playAreas.forEach((playArea: any) => {
        playArea.side =
          playArea === myPlayArea ? Deck.Card.Side.FRONT : Deck.Card.Side.BACK;
        playArea.layout();
      });
    });
  };

  // tslint:disable-next-line:variable-name
  const DEBUG_discardDrawPile = () => {
    const totalDrawPileCards = drawPile.cards.length;
    for (let i = 0; i < totalDrawPileCards; ++i) {
      discardPile.pushCard(drawPile.popCard());
    }
    deck.withQueueTransaction(() => {
      drawPile.layout();
      discardPile.layout();
    });
    handleUpdateGame({
      deck: deck.serialize(),
    });
  };

  return (
    <Grid.Row>
      <Grid.Column width={11} className="no-padding">
        <div className="play-area">
          {playersLoading
            ? ''
            : playerRefs.map((playerRef, idx) => {
                const player = players.find((p) => p.id === playerRef.id);
                if (player) {
                  const meX = 0;
                  const meHandY = 250;
                  const theta =
                    ((2 * Math.PI) / playerRefs.length) * (idx - playerIndex);
                  const avatarX =
                    meX * Math.cos(theta) - (meHandY + 85) * Math.sin(theta);
                  const avatarY =
                    meX * Math.sin(theta) + (meHandY + 85) * Math.cos(theta);
                  const style = {
                    transform: `translate(${avatarX}px, ${avatarY}px)`,
                  };

                  return (
                    <div
                      key={player.id}
                      className={
                        currentPlayer === player.id
                          ? 'player-avatar glow'
                          : 'player-avatar'
                      }
                      style={style}
                    >
                      <img src={getAvatar(player)} alt="player avatar" />
                    </div>
                  );
                }
                return null;
              })}
          {gameStatus === GameStatus.PENDING ? (
            <div className="game-status">
              <Header size="large" className="game-status-header">
                Waiting for more players to join...
              </Header>
            </div>
          ) : gameStatus === GameStatus.READY ? (
            imTheCreator ? (
              <div className="game-status">
                <Button
                  size="massive"
                  color="yellow"
                  className="game-status-button"
                  onClick={startGame}
                >
                  Start Game
                </Button>
              </div>
            ) : (
              <div className="game-status">
                <Header size="large" className="game-status-header">
                  Waiting for game to begin...
                </Header>
              </div>
            )
          ) : (
            ''
          )}
          <Container>
            {DEBUG ? (
              <Rail internal attached position="left">
                <Button
                  size="small"
                  onClick={() => {
                    setShowDebugControls(!showDebugControls);
                  }}
                >
                  {showDebugControls ? 'Hide Debug Panel' : 'Show Debug Panel'}
                </Button>
                {showDebugControls ? (
                  <DebugControls
                    players={players}
                    myPlayerIdx={playerIndex}
                    debugApi={{
                      DEBUG_resetGame,
                      DEBUG_forceDeal,
                      DEBUG_forceMultiples,
                      DEBUG_forcePeek,
                      DEBUG_forceTurn,
                      DEBUG_showTable,
                      DEBUG_hideTable,
                      DEBUG_discardDrawPile,
                    }}
                  />
                ) : (
                  ''
                )}
              </Rail>
            ) : (
              ''
            )}
          </Container>

          <div id="card-table" />
        </div>
      </Grid.Column>
      <Grid.Column width={5} className="game-sidebar">
        <Container>
          <Header size="large" textAlign="center" className="fancy">
            {gameName}
          </Header>
          {playersLoading ? (
            <Loader />
          ) : (
            <Segment>
              <Grid divided="vertically">
                {playerRefs.map((playerRef) => {
                  const player = players.find((p) => p.id === playerRef.id)!;
                  if (!player) {
                    return '';
                  }
                  const { name, state } = player;
                  const scores = playerRef?.scores ? playerRef.scores : [];
                  return (
                    <Grid.Row key={player.id}>
                      <Grid.Column width={12}>
                        <div
                          className={
                            state === 'online'
                              ? 'avatar-status avatar-online'
                              : 'avatar-status avatar-offline'
                          }
                        >
                          <Image src={getAvatar(player)} avatar />
                        </div>
                        <span className="player-name">
                          {currentPlayer === player.id
                            ? name + ' (turn)'
                            : name}
                        </span>
                      </Grid.Column>
                      <Grid.Column width={4}></Grid.Column>
                    </Grid.Row>
                  );
                })}
              </Grid>
            </Segment>
          )}
        </Container>
        <Container>
          <Header size="medium" textAlign="center">
            Scores
          </Header>
          <Table celled basic fixed textAlign="right">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell key="rounds">Round</Table.HeaderCell>
                {playerRefs.map((playerRef) => {
                  const player = players.find((p) => p.id === playerRef.id)!;
                  if (!player) {
                    return (
                      <Table.HeaderCell key={playerRef.id}></Table.HeaderCell>
                    );
                  }
                  const { name, state } = player;
                  const scores = playerRef?.scores ? playerRef.scores : [];
                  return (
                    <Table.HeaderCell key={player.id}>{name}</Table.HeaderCell>
                  );
                })}
              </Table.Row>
            </Table.Header>
            <Table.Body>{scoreRows}</Table.Body>
            <Table.Footer>{scoreTotals}</Table.Footer>
          </Table>
        </Container>
      </Grid.Column>
    </Grid.Row>
  );
};

export default PlayArea;
