import React from 'react';
import { Route, Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import reducer from './reducer';
import Login from './screens/Login';
import Main from './screens/Main';
import Register from './screens/Register';
import { IGlobalState, StateProvider } from './state';
import usePlayerAuth from './utils/usePlayerAuth';

const Router = () => {
  const [, loading, error] = usePlayerAuth();

  if (loading) {
    return (
      <div className="botrice-loader">
        <h1>Botrice!</h1>
      </div>
    );
  }
  if (error) {
    return (
      <div>
        <p>Error: {error}</p>
      </div>
    );
  }

  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <PrivateRoute path="*" component={Main} />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

const initialState: IGlobalState = {
  player: null,
  game: null,
};

const App = () => {
  return (
    <StateProvider reducer={reducer} initialState={initialState}>
      <Router />
    </StateProvider>
  );
};

export default App;
