import * as firebase from 'firebase/app';
import 'firebase/auth';
import React, { useState } from 'react';
import { useObject } from 'react-firebase-hooks/database';
import { useParams } from 'react-router';
import { Grid, Header, Loader } from 'semantic-ui-react';
import PlayArea from '../components/PlayArea';
import { IGame } from '../types/IGameDocument';
import createRTDBDocument from '../utils/createRTDBDocument';

const Game = () => {
  const { gameId } = useParams();

  const [gameUpdateError, setGameUpdateError] = useState<Error | null>(null);

  const gameRTDBQuery = gameId
    ? firebase.database().ref(`games/${gameId}`)
    : null;
  const [currentGame, currentGameLoading, currentGameError] = useObject(
    gameRTDBQuery
  );

  const updateDB = (pathPrefix: string = '') => {
    return async (data: any, path: string = '') => {
      try {
        const fullPath = [pathPrefix, path].join('/');
        await firebase.database().ref(fullPath).update(data);
      } catch (e) {
        setGameUpdateError(e);
      }
    };
  };

  return (
    <Grid padded="horizontally" className="play-grid">
      {currentGameLoading && <Loader />}
      {currentGame && gameId && (
        <PlayArea
          game={createRTDBDocument<IGame>(currentGame, gameId)}
          handleUpdateGame={updateDB(`games/${gameId}`)}
          updateError={gameUpdateError}
        />
      )}
      {currentGameError && (
        <Header size="huge">Error: {currentGameError.message}</Header>
      )}
      {gameUpdateError && (
        <Header size="huge">Error: {gameUpdateError.message}</Header>
      )}
      {!currentGame && !currentGameLoading ? (
        <Header size="huge">Choose a game join, or start your own!</Header>
      ) : (
        ''
      )}
    </Grid>
  );
};

export default Game;
