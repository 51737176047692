import * as firebase from 'firebase/app';
import 'firebase/auth';
import React, { useState } from 'react';
import { Route, Switch } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Dropdown, Icon, Menu } from 'semantic-ui-react';
import { ActionType } from '../reducer';
import { useStateValue } from '../state';
import Game from './Game';
import Lobby from './Lobby';

const Main = () => {
  const [{ player }, dispatch] = useStateValue();

  const [signoutError, setSignoutError] = useState<Error | null>(null);

  const logout = async () => {
    try {
      dispatch({ type: ActionType.SET_PLAYER, payload: null });
      await firebase.auth().signOut();
    } catch (e) {
      setSignoutError(e);
    }
  };

  return (
    <div className="main-container">
      <Menu attached="top" inverted borderless>
        <Menu.Item header>
          <NavLink to="/">Botrice!</NavLink>
        </Menu.Item>
        <Dropdown
          className="link item positioned right"
          text={player?.data.name}
        >
          <Dropdown.Menu>
            <Dropdown.Item as="a" onClick={logout}>
              <Icon name="log out" />
              Log Out
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Menu>
      <div className="main-content">
        <Switch>
          <Route path="/" exact component={Lobby} />
          <Route path="/game/:gameId" component={Game} />
        </Switch>
      </div>
      {signoutError && signoutError.message}
    </div>
  );
};

export default Main;
