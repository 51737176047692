import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { useStateValue } from '../state';

const PrivateRoute = (props: RouteProps) => {
  const [{ player }] = useStateValue();

  if (!player) {
    return (
      <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    );
  }
  return <Route {...props} />;
};

export default PrivateRoute;
