import React from 'react';
import { Card, Divider, Image } from 'semantic-ui-react';
import { GameStatus } from '../constants';
import { IGame } from '../types/IGameDocument';
import getAvatar from '../utils/getAvatar';

interface IProps {
  game: IGame;
  onClick: () => void;
}

const gameStatusString = (gameStatus: GameStatus): string => {
  switch (gameStatus) {
    case GameStatus.PENDING: {
      return 'Waiting for more players to join';
    }
    case GameStatus.READY: {
      return 'Ready to start';
    }
    case GameStatus.OPEN: {
      return 'In progress';
    }
    case GameStatus.CLOSED: {
      return 'Finished';
    }
  }
};

const GameCard = ({ game, onClick }: IProps) => {
  return (
    <Card key={game.id} className="lobby-card" raised onClick={onClick}>
      <Card.Content>
        <Card.Header>{game.name}</Card.Header>
        <Divider />
        <Image.Group size="mini">
          {game.players.map((player) => {
            return <Image key={player.id} rounded src={getAvatar(player)} />;
          })}
        </Image.Group>
      </Card.Content>
      <Card.Content extra>{gameStatusString(game.status)}</Card.Content>
    </Card>
  );
};

export default GameCard;
