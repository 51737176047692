import { CardRank } from '../constants';

import {
  executeAction,
  createAction_Sync,
  createAction_Draw,
  createAction_Discard,
  createAction_Move,
  createAction_Swap,
  createAction_SwapIntoHand,
  createAction_Penalty,
  createAction_ResolvePenalty,
} from './Actions';

export enum TurnState {
  START = 'start', // Beginning of turn (can botrice, draw, etc.)
  PLAY = 'play', // Card(s) are in the play area ready to be discarded or swapped into hand (can also happen after discarding a king)
  PEEK = 'peek', // Just discarded a jack, ready to peek
  PEEKING = 'peeking', // Actively peeking at a card after playing a jack
  SWAP = 'swap', // Just discarded a queen, ready to swap,
  PENALTY = 'penalty', // Tried to discard directly to the draw pile incorrectly
}

interface TableState {
  deck: any;
  drawPile: any;
  discardPile: any;
  botriceArea: any;
  myHand: any;
  myPlayArea: any;
  myPenaltyArea: any;
  hands: any;
}

interface GameApi {
  pushAction: (action: any) => void;
  pushTurnState: (turnState: TurnState) => void;
  endTurn: () => void;
  botrice: () => void;
}

type TransitionHandler = (nextState: TurnState, context?: any) => void;

/*
 * Entry point for turn
 */

export const myTurn = (
  initialState: TurnState,
  tableState: TableState,
  gameApi: GameApi,
  initialContext?: any
) => {
  const { pushTurnState } = gameApi;

  const transitionTo = (nextState: TurnState, context?: any) => {
    switch (nextState) {
      case TurnState.START: {
        handleStartState(tableState, gameApi, transitionTo);
        break;
      }
      case TurnState.PLAY: {
        handlePlayState(tableState, gameApi, transitionTo);
        break;
      }
      case TurnState.PEEK: {
        handlePeekState(tableState, gameApi, transitionTo);
        break;
      }
      case TurnState.PEEKING: {
        handlePeekingState(tableState, gameApi, transitionTo, context);
        break;
      }
      case TurnState.SWAP: {
        handleSwapState(tableState, gameApi);
        break;
      }
      case TurnState.PENALTY: {
        handlePenaltyState(tableState, gameApi, transitionTo, context);
        break;
      }
      default:
        break;
    }
    pushTurnState(nextState);
  };

  transitionTo(initialState, initialContext);
};

/*
 *  TableState.START
 */

const handleStartState = (
  tableState: TableState,
  gameApi: GameApi,
  transitionTo: TransitionHandler
) => {
  const {
    deck,
    drawPile,
    discardPile,
    botriceArea,
    myHand,
    myPlayArea,
    myPenaltyArea,
  } = tableState;
  const { pushAction, endTurn, botrice } = gameApi;

  const enter = () => {
    setupUi();

    // Reshuffle, if necessary
    if (drawPile.cards.length === 0) {
      reshuffleDiscardToDraw();
    }

    // Setup drawing from the draw pile
    drawPile.onSelect = executeDraw;
    drawPile.enableSelection(true);

    // Set up hand for playing a single card or multiples directly to the discard pile
    myHand.onSelect = (selectedCard: any, index: number) => {
      if (myHand.cardAtIndexIsProminent(index)) {
        myHand.setCardProminentAtIndex(index, false);
      } else if (myHand.prominentCards.size < 4) {
        myHand.setCardProminentAtIndex(index, true);
        if (myHand.prominentCards.size === 1) {
          deck.withQueueTransaction(() => {
            botriceArea.showHelper(false);
            drawPile.showHelper(false);
            drawPile.enableSelection(false);
          });
        }
      }

      if (myHand.prominentCards.size > 0) {
        if (myHand.prominentCards.size === 1) {
          // Setup to play a single card (if possible)
          if (discardPile.cards.length > 0) {
            const button = createHelperButton(`Play matching card`, {
              onClick: playMatchingDiscard,
            });
            myHand.showHelper(true, { content: button });
          } else {
            myHand.showHelper(true, {
              content: createHelperLabel(
                'Must play 2 or more cards on empty discard pile'
              ),
            });
          }
        } else if (myHand.prominentCards.size > 1) {
          // Setup to play multiples
          let multiples = '';
          if (myHand.prominentCards.size === 2) {
            multiples = 'doubles';
          } else if (myHand.prominentCards.size === 3) {
            multiples = 'triples';
          } else if (myHand.prominentCards.size === 4) {
            multiples = 'quadruples';
          }

          const button = createHelperButton(`Play ${multiples}`, {
            onClick: playMultiples,
          });
          myHand.showHelper(true, { content: button });
        }
      } else {
        // If we've decided not to play from the hand, reset the helper indicators
        // and click handlers on the draw pile, etc.
        myHand.layout();
        setupUi();
        drawPile.enableSelection(true);
      }
      myHand.layout('animate card prominence');
    };
    myHand.enableSelection(true);
  };

  const setupUi = () => {
    // Setup helper UI
    deck.withQueueTransaction(() => {
      const botriceButton = createHelperButton('Botrice!', {
        size: 'large',
        onClick: callBotrice,
      });
      const botriceHelper = createHelperLabel('Call Botrice...');
      const botriceContainer = createHelperContainer([
        botriceButton,
        botriceHelper,
      ]);
      botriceArea.showHelper(true, { content: botriceContainer });
      drawPile.showHelper(true, {
        content: createHelperLabel('...or draw a card...'),
      });
      myHand.showHelper(true, {
        content: createHelperLabel(
          '...or select card(s) to play directly to the discard pile'
        ),
      });
    });
  };

  const tearDownUi = () => {
    deck.withQueueTransaction(() => {
      botriceArea.showHelper(false);
      drawPile.showHelper(false);
      myHand.showHelper(false);
      drawPile.enableSelection(false);
      myHand.enableSelection(false);
    });
  };

  const executeDraw = () => {
    tearDownUi();

    // Perform the draw
    const drawAction = createAction_Draw(drawPile, myPlayArea);
    executeAction(drawAction, deck);
    pushAction(drawAction);

    transitionTo(TurnState.PLAY);
  };

  const callBotrice = () => {
    tearDownUi();
    botrice();
  };

  const playMatchingDiscard = () => {
    tearDownUi();

    const topDiscard = discardPile.cards[discardPile.cards.length - 1];
    const cardToPlayIndex = myHand.prominentCards.values().next().value;
    const cardToPlay = myHand.cards[cardToPlayIndex];

    if (cardToPlay.rank === topDiscard.rank) {
      // Success!
      const discardAction = createAction_Discard(
        myHand,
        [cardToPlayIndex],
        discardPile
      );
      executeAction(discardAction, deck);

      // @TODO: Include description/reason text with pushed action?
      pushAction(discardAction);

      handleDiscard(cardToPlay.rank);
    } else {
      // Uh oh. Bad news.
      const penaltyAction = createAction_Penalty(
        myHand,
        [cardToPlayIndex],
        myPenaltyArea
      );
      executeAction(penaltyAction, deck);
      pushAction(penaltyAction);

      transitionTo(TurnState.PENALTY, { penaltyAction });
    }
  };

  const playMultiples = () => {
    tearDownUi();

    // Check to see if the cards actually match
    const cardsToPlayIndices = Array.from(
      myHand.prominentCards
    ).sort() as number[];
    const rank = myHand.cards[cardsToPlayIndices[0]].rank;
    let allMatch = true;
    for (let i = 1; i < cardsToPlayIndices.length; ++i) {
      const checkRank = myHand.cards[cardsToPlayIndices[i]].rank;
      if (checkRank !== rank) {
        allMatch = false;
        break;
      }
    }

    if (allMatch) {
      // Success!
      const discardAction = createAction_Discard(
        myHand,
        cardsToPlayIndices,
        discardPile
      );
      executeAction(discardAction, deck);
      pushAction(discardAction);

      handleDiscard(rank);
    } else {
      // Uh oh. You did bad.
      const penaltyAction = createAction_Penalty(
        myHand,
        cardsToPlayIndices,
        myPenaltyArea
      );
      executeAction(penaltyAction, deck);
      pushAction(penaltyAction);

      transitionTo(TurnState.PENALTY, { penaltyAction });
    }
  };

  const reshuffleDiscardToDraw = () => {
    // Save the top discard and put it back
    // Only shuffle the discards under the top card
    const topDiscard = discardPile.popCard();
    const totalDiscards = discardPile.cards.length;
    for (let i = 0; i < totalDiscards; ++i) {
      drawPile.pushCard(discardPile.popCard());
    }
    // Put the top card back
    discardPile.pushCard(topDiscard);

    drawPile.layout();
    discardPile.layout();
    drawPile.shuffle();
    drawPile.shuffle();
    drawPile.layout();

    // @TODO: Maybe this should be its own "reshuffle" action?
    pushAction(createAction_Sync(deck));
  };

  const handleDiscard = (rank: CardRank) => {
    switch (rank) {
      case CardRank.JACK: {
        transitionTo(TurnState.PEEK);
        break;
      }
      case CardRank.QUEEN: {
        transitionTo(TurnState.SWAP);
        break;
      }
      case CardRank.KING: {
        // Draw two additional cards
        const drawAction = createAction_Draw(drawPile, myPlayArea);

        // Each time we draw, make sure there's a card in the pile to draw!
        // Otherwise, reshuffle the discard pile
        if (drawPile.cards.length === 0) {
          reshuffleDiscardToDraw();
        }
        executeAction(drawAction, deck);
        if (drawPile.cards.length === 0) {
          reshuffleDiscardToDraw();
        }
        executeAction(drawAction, deck);
        pushAction(drawAction);
        pushAction(drawAction);
        transitionTo(TurnState.PLAY);
        break;
      }
      default: {
        endTurn();
        break;
      }
    }
  };

  enter();
};

/*
 *  TableState.PLAY
 */

const handlePlayState = (
  tableState: TableState,
  gameApi: GameApi,
  transitionTo: TransitionHandler
) => {
  const { deck, drawPile, discardPile, myHand, myPlayArea } = tableState;
  const { pushAction, endTurn } = gameApi;

  const enter = () => {
    if (myPlayArea.cards.length === 1) {
      setupPlayUi();
    } else {
      setupChooseUi();
    }
  };

  const setupPlayUi = () => {
    deck.withQueueTransaction(() => {
      discardPile.showHelper(true, {
        content: createHelperLabel('Discard it...'),
      });
      myHand.showHelper(true, {
        content: createHelperLabel('...or select card to swap and discard'),
      });

      // Set up swapping with a card in my hand
      myHand.onSelect = swap;
      myHand.enableSelection(true);

      // Set up discarding
      discardPile.onSelect = discard;
      discardPile.enableSelection(true);
    });
  };

  const setupChooseUi = () => {
    deck.withQueueTransaction(() => {
      myPlayArea.showHelper(true, {
        content: createHelperLabel('Choose a card to play'),
      });
      myPlayArea.onSelect = chooseCard;
      myPlayArea.enableSelection(true);
      myPlayArea.layout();
    });
  };

  const tearDownPlayUi = () => {
    deck.withQueueTransaction(() => {
      discardPile.showHelper(false);
      myHand.showHelper(false);
      discardPile.enableSelection(false);
      myHand.enableSelection(false);
    });
  };

  const tearDownChooseUi = () => {
    myPlayArea.showHelper(false);
  };

  const chooseCard = (card: any, index: number) => {
    if (myPlayArea.cardAtIndexIsProminent(index)) {
      myPlayArea.setCardProminentAtIndex(index, false);
      tearDownPlayUi();
      setupChooseUi();
    } else if (myPlayArea.prominentCards.size === 0) {
      tearDownChooseUi();
      setupPlayUi();
      myPlayArea.setCardProminentAtIndex(index, true);
    }
    discardPile.enableSelection(true);
    myHand.enableSelection(true);
    myPlayArea.layout();
  };

  const swap = (selectedCard: any, index: number) => {
    tearDownPlayUi();

    const chosenCardIdx =
      myPlayArea.cards.length > 1
        ? myPlayArea.prominentCards.values().next().value
        : 0;
    const swapped = myHand.cards[index];
    const swapAction = createAction_SwapIntoHand(
      myPlayArea,
      chosenCardIdx,
      myHand,
      index,
      discardPile
    );
    executeAction(swapAction, deck);
    pushAction(swapAction);

    // Only handle face card logic if it's the final discard
    if (myPlayArea.cards.length === 0) {
      handleDiscard(swapped.rank);
    } else {
      enter();
    }
  };

  const discard = () => {
    tearDownPlayUi();

    const toDiscardIndex =
      myPlayArea.cards.length > 1
        ? myPlayArea.prominentCards.values().next().value
        : 0;

    const toDiscard = myPlayArea.cards[toDiscardIndex];
    const discardAction = createAction_Discard(
      myPlayArea,
      [toDiscardIndex],
      discardPile
    );
    executeAction(discardAction, deck);
    pushAction(discardAction);

    // Only handle face card logic if it's the final discard
    if (myPlayArea.cards.length === 0) {
      handleDiscard(toDiscard.rank);
    } else {
      enter();
    }
  };

  const reshuffleDiscardToDraw = () => {
    // Save the top discard and put it back
    // Only shuffle the discards under the top card
    const topDiscard = discardPile.popCard();
    const totalDiscards = discardPile.cards.length;
    for (let i = 0; i < totalDiscards; ++i) {
      drawPile.pushCard(discardPile.popCard());
    }
    // Put the top card back
    discardPile.pushCard(topDiscard);

    drawPile.layout();
    discardPile.layout();
    drawPile.shuffle();
    drawPile.shuffle();
    drawPile.layout();

    // @TODO: Maybe this should be its own "reshuffle" action?
    pushAction(createAction_Sync(deck));
  };

  const handleDiscard = (rank: CardRank) => {
    switch (rank) {
      case CardRank.JACK: {
        transitionTo(TurnState.PEEK);
        break;
      }
      case CardRank.QUEEN: {
        transitionTo(TurnState.SWAP);
        break;
      }
      case CardRank.KING: {
        // Draw two additional cards
        const drawAction = createAction_Draw(drawPile, myPlayArea);

        // Each time we draw, make sure there's a card in the pile to draw!
        // Otherwise, reshuffle the discard pile
        if (drawPile.cards.length === 0) {
          reshuffleDiscardToDraw();
        }
        executeAction(drawAction, deck);
        if (drawPile.cards.length === 0) {
          reshuffleDiscardToDraw();
        }
        executeAction(drawAction, deck);
        pushAction(drawAction);
        pushAction(drawAction);
        transitionTo(TurnState.PLAY);
        break;
      }
      default: {
        endTurn();
        break;
      }
    }
  };

  enter();
};

/*
 *  TableState.PEEK
 */

const handlePeekState = (
  tableState: TableState,
  gameApi: GameApi,
  transitionTo: TransitionHandler
) => {
  const { deck, botriceArea, myPlayArea, hands } = tableState;
  const { pushAction } = gameApi;

  const enter = () => {
    deck.withQueueTransaction(() => {
      hands.forEach((hand: any) => {
        hand.onSelect = (card: any, index: number) => {
          chooseCard(hand, card, index);
        };
        hand.enableSelection(true);
      });
      botriceArea.showHelper(true, {
        content: createHelperHeader('Select a card to peek at', 'h4'),
      });
    });
  };

  const tearDownUi = () => {
    deck.withQueueTransaction(() => {
      hands.forEach((h: any) => h.enableSelection(false));
      botriceArea.showHelper(false);
    });
  };

  const chooseCard = (hand: any, card: any, index: number) => {
    tearDownUi();

    const peekAction = createAction_Move(hand, index, myPlayArea, 0);
    executeAction(peekAction, deck);
    pushAction(peekAction);

    transitionTo(TurnState.PEEKING, { peekAction });
  };

  enter();
};

/*
 *  TableState.PEEKING
 */

const handlePeekingState = (
  tableState: TableState,
  gameApi: GameApi,
  transitionTo: TransitionHandler,
  context?: any
) => {
  const { deck, myPlayArea } = tableState;
  const { pushAction, endTurn } = gameApi;

  const enter = () => {
    myPlayArea.showHelper(true, {
      content: createHelperButton('Done Peeking!', {
        onClick: finishPeeking,
      }),
    });
  };

  const finishPeeking = () => {
    myPlayArea.showHelper(false);

    const peekAction = context!.peekAction;
    const hand = deck.cliques[peekAction.from];

    const donePeekingAction = createAction_Move(
      myPlayArea,
      0,
      hand,
      peekAction.fromIndex
    );
    executeAction(donePeekingAction, deck);
    pushAction(donePeekingAction);

    endTurn();
  };

  enter();
};

/*
 *  TableState.SWAP
 */

const handleSwapState = (tableState: TableState, gameApi: GameApi) => {
  const { deck, botriceArea, myPlayArea, hands } = tableState;
  const { pushAction, endTurn } = gameApi;

  const getProminentForHandCount = () => {
    return hands.reduce((num: number, h: any) => {
      return h.prominentCards.size + num;
    }, 0);
  };

  const enter = () => {
    showInstructionsUi();

    hands.forEach((hand: any) => {
      hand.onSelect = (card: any, index: number) => {
        if (hand.cardAtIndexIsProminent(index)) {
          hand.setCardProminentAtIndex(index, false);
        } else if (getProminentForHandCount() < 2) {
          hand.setCardProminentAtIndex(index, true);
        }
        if (getProminentForHandCount() === 2) {
          tearDownInstructionsUi();
          showSwapUi();
        } else {
          tearDownSwapUi();
          showInstructionsUi();
        }
        hand.layout();
      };
      hand.enableSelection(true);
    });
  };

  const showInstructionsUi = () => {
    botriceArea.showHelper(true, {
      content: createHelperHeader('Select two cards to swap', 'h4'),
    });
  };

  const tearDownInstructionsUi = () => {
    botriceArea.showHelper(false);
  };

  const showSwapUi = () => {
    myPlayArea.showHelper(true, {
      content: createHelperButton('Swap!', {
        size: 'large',
        onClick: swap,
      }),
    });
  };

  const tearDownSwapUi = () => {
    myPlayArea.showHelper(false);
  };

  const swap = () => {
    hands.forEach((h: any) => h.enableSelection(false));
    myPlayArea.showHelper(false);
    const [card1, card2] = hands.flatMap((hand: any) => {
      return Array.from(hand.prominentCards).map((idx: any) => {
        return { hand, idx };
      });
    });

    const swapAction = createAction_Swap(
      card1.hand,
      card1.idx,
      card2.hand,
      card2.idx
    );
    executeAction(swapAction, deck);
    pushAction(swapAction);

    endTurn();
  };

  enter();
};

/*
 *  TableState.PENALTY
 */

const handlePenaltyState = (
  tableState: TableState,
  gameApi: GameApi,
  transitionTo: TransitionHandler,
  context?: any
) => {
  const { deck, drawPile, myPenaltyArea } = tableState;
  const { pushAction, endTurn } = gameApi;

  const enter = () => {
    deck.withQueueTransaction(() => {
      const labelText =
        myPenaltyArea.cards.length === 1
          ? "Yikes! Card didn't match!"
          : "Yikes! Cards didn't match each other!";
      const alertLabel = createHelperLabel(labelText, { alert: true });
      myPenaltyArea.showHelper(true, { content: alertLabel });

      const takePenaltyButton = createHelperButton('Take Penalty', {
        color: 'red',
        onClick: takePenalty,
      });
      drawPile.showHelper(true, { content: takePenaltyButton });
    });
  };

  const tearDownUi = () => {
    deck.withQueueTransaction(() => {
      drawPile.showHelper(false);
      myPenaltyArea.showHelper(false);
    });
  };

  const takePenalty = () => {
    tearDownUi();

    const penaltyAction = context!.penaltyAction;
    const resolvePenaltyAction = createAction_ResolvePenalty(
      penaltyAction,
      drawPile
    );
    executeAction(resolvePenaltyAction, deck);
    pushAction(resolvePenaltyAction);

    endTurn();
  };

  enter();
};

/*
 * Convenience functions for creating helper UI elements
 */

export const createHelperLabel = (labelText: string, options?: any) => {
  const label = document.createElement('div');
  label.classList.add('ui', 'label', 'left', 'pointing', 'animated');
  if (options && options.alert) {
    label.classList.add('red');
    label.classList.remove('animated');
  }
  label.textContent = labelText;
  return label;
};

export const createHelperButton = (buttonText: string, options?: any) => {
  const button = document.createElement('button');
  button.classList.add('ui');
  button.classList.add('button');
  button.classList.add(options && options.color ? options.color : 'teal');
  button.classList.add(options && options.size ? options.size : 'small');
  button.textContent = `${buttonText}`;
  button.onclick = options && options.onClick ? options.onClick : null;
  return button;
};

export const createHelperContainer = (elements: any[]) => {
  const container = document.createElement('div');
  container.classList.add('helper-container');
  elements.forEach((el) => {
    container.appendChild(el);
  });
  return container;
};

export const createHelperHeader = (
  headerText: string,
  headerElement?: string
) => {
  const header = document.createElement(headerElement ? headerElement : 'h1');
  header.classList.add('ui', 'header');
  header.style.textAlign = 'center';
  header.textContent = headerText;
  return header;
};
