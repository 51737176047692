import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/database';
import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Collection } from '../constants';
import { ActionType } from '../reducer';
import { useStateValue } from '../state';
import { IPlayer } from '../types/IPlayerDocument';
import createDocument from './createDocument';

const isOfflineFS = {
  state: 'offline',
  last_changed: firebase.firestore.FieldValue.serverTimestamp(),
};

const isOnlineFS = {
  state: 'online',
  last_changed: firebase.firestore.FieldValue.serverTimestamp(),
};

const isOfflineDB = {
  state: 'offline',
  last_changed: firebase.database.ServerValue.TIMESTAMP,
};

const isOnlineDB = {
  state: 'online',
  last_changed: firebase.database.ServerValue.TIMESTAMP,
};

const usePlayerAuth = () => {
  const [{ player: globalPlayer }, dispatch] = useStateValue();
  const [user, initialising, error] = useAuthState(firebase.auth());

  const [authError, setAuthError] = useState(error);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (error) {
      setLoading(false);
      setAuthError(error);
    }
    const getPlayer = async () => {
      if (globalPlayer) {
        setLoading(false);
      }
      if (user && !globalPlayer) {
        try {
          const playerDoc = (await firebase
            .firestore()
            .collection(Collection.PLAYERS)
            .doc(user.uid)
            .get()) as firebase.firestore.DocumentSnapshot<IPlayer>;
          const playerData = { ...playerDoc.data(), user };
          dispatch({
            type: ActionType.SET_PLAYER,
            payload: createDocument(playerDoc, playerData),
          });
          const userStatusDatabaseRef = firebase
            .database()
            .ref(`/status/${playerDoc.id}`);

          firebase
            .database()
            .ref('.info/connected')
            .on('value', async (snapshot) => {
              if (snapshot.val() === false) {
                await playerDoc.ref.update({ ...isOfflineFS });
                return;
              }
              await userStatusDatabaseRef.onDisconnect().set(isOfflineDB);
              await Promise.all([
                userStatusDatabaseRef.set(isOnlineDB),
                playerDoc.ref.update({ ...isOnlineFS }),
              ]);
            });
        } catch (e) {
          if (e.code !== 'permission-denied') {
            setAuthError(e);
          }
        }
      } else if (!initialising) {
        setLoading(false);
      }
    };
    getPlayer();
    // eslint-disable-next-line
  }, [initialising, user, error, globalPlayer]);
  return [globalPlayer, loading, authError];
};

export default usePlayerAuth;
